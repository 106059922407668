.map_bottom_section {
  display: flex;
  flex-wrap: wrap;
  row-gap: rem(40px);
  column-gap: 0;
  @include media-max($lg) {
    justify-content: center;
    column-gap: 40px;

  }
  .single_box {
    flex: 1;
    margin-left: rem(40px);
    min-height: 100%;
    margin-bottom: 0;
    @include media-max($lg) {
      margin-left: 0;      
    }
  }
}
.mini_map {
  background:gray;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;  
  border: 1px solid $border;
  width: 680px;
  min-height: 420px;
  @include media-max($lg) {
    width: 100%;
    min-height: 560px;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius:0;
    border-bottom-right-radius: 0; 
  }
}