// ------------------------------
// Variables
// ------------------------------

//Font
$font-family: 'Poppins', sans-serif;

//Breakpoints
$sm: 640px;
$md: 960px;
$lg: 1200px;
$xl: 1600px;
//Colors
$link: #27AE60;
$green:#17C4A8;
$green_2: #27AE60;
$blue: #3498DB;
$text: #000;
$red: #E74C3C;
$black_bg: #272727; 
$white: #fff;
$gray_txt: #707070;
$border: #DBDBDB;
$orange: #ef8354;
$gray-col:#c9c6c6;
$gray-col-t: #f6f5f5;

//Transitions
$t-color: color .3s ease-in-out;
$t-bottom: bottom .3s ease-in-out;
$t-bc: background-color .3s ease-in-out;
$t-bd-b: border-bottom .3s ease-in-out;
$t-bd-col: border-color .3s ease-in-out;
$t-left: left .3s ease-in-out;
$t-margin: margin .3s ease-in-out;
$t-opacity: opacity .3s ease-in-out;
$t-top: top .3s ease-in-out;
$t-trans: transform .3s ease-in-out;
$t-width: width .3s ease-in-out;
$t-all: all .3s ease-in-out;
$t-all-f: all .1s ease-in-out;

