.pro_btn,
.pro_green_btn,
.pro_btn_dark {
  border-radius: 50px;
  font-size: 18px;
  background: transparent;
  transition: 0.1s all;
  padding: 1.25rem 3.125rem;
  @include media-max($md) {
    padding: 0.8rem 2rem;
  }
  @include media-max($sm) {
    padding: 5px 12px;
    font-size: 10px;
  }
}

.pro_btn {
  border: 1px solid #fff;
  font-weight: 500;
  color: #fff;
  img {
    margin-right: rem(15px);
    @include media-max($lg) {
      margin-right: rem(5px);
      width: 30px;
    }
    @include media-max($sm) {
      width: 18px;
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.212);
  }
}
.pro_btn_dark {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $text;
  gap: 10px;
  font-weight: 500;
  color: $text;
  @include media-min($md) {
    margin-left: auto;
  }
  svg:not(.burger_menu > svg) {
    max-width: 35px;
    margin-right: rem(15px);
    @include media-max($lg) {
      margin-right: rem(5px);
      width: 30px;
    }
    @include media-max($sm) {
      width: 18px;
    }
  }
  svg {
    path {
      transition: 0.3s all ease-in-out;
    }
  }
  &:hover {
    background: rgba(255, 255, 255, 0.212);
    border-color: $green;
  }
  &:hover svg {
    path {
      stroke: $green;
    }
  }
}
.pro_green_btn {
  border: 1px solid $green;
  color: $green;
  &:hover {
    background: $green;
    color: #fff;
  }
  @include media-max($lg) {
    padding: 0.8rem 1.5rem;
  }
}
.nav_button {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.orange-btn {
  background-color: $orange;
  justify-content: center;
  border-radius: 30px;
  color: #fff;
  transition: .3s;
  padding: 10px 25px;
  // column-gap: rem(50px);
  white-space: nowrap;
  &:hover {
      background-color: #ee5d1e;
      color: #fff;
  }
}
