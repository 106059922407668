.scroll_down,
.scroll_down_subpage {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  svg {
    transform: scale(1.5);
  }
}
.scroll_down_subpage {
  @include media-max($md) {
    display: none;
  }
}
.bounce {
  animation: bounce 2s infinite;
}
.scroll_down_subpage {
  polyline {
    stroke: #000 !important;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-10px);
  }
}
