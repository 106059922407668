.text_columns {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: rem(40px);
  font-size: 15px;
  @include media-max($md) {
    grid-template-columns: 1fr;
  }
}
.boxes_wrapper {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  column-gap: rem(40px);

  @include media-max($md) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    column-gap: rem(20px);
  }
}

.single_box {
  padding: rem(70px 60px 30px);
  font-weight: 500;
  font-size: 16px;
  // width: 450px;
  border-radius: 25px;
  border: 1px solid $border;
  margin-bottom: 20px;
  color: $text;
  @include media-max($md) {
    padding: rem(30px 15px 20px);
  }
  li {
    display: flex;
    margin-bottom: 15px;
  }
  > .more {
    text-decoration: underline;
    font-weight: 600;
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
  }
}
.entry-list {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  row-gap: 15px;
}
.single_entry {
  padding: 15px;
  border-radius: 0;
  border: 1px solid $border;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.15);
  img {
    max-height: 310px;
    width: 100%;
  }
  .badge {
    text-transform: uppercase;
    position: absolute;
    top: 25px;
    left: 25px;
    background: $orange;
    color: #fff;
    padding: rem(5px 15px);
  }
  .title {
    margin: 10px 0;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .date {
    font-size: 14px;
    text-transform: uppercase;
    color: $text;
    margin: 10px 0;
    font-weight: 500;
  }
  .description {
    opacity: 0.9;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }
  .more {
    margin-top: auto;
    font-size: 15px;
  }
  &:hover {
    color: unset;
    box-shadow: none;
  }
}
.s-filters {
  &:after {
    display: none;
    @include media-max($sm) {
      display: block;
      content: "";
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      height: 100%;
      width: 35px;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 17%,
        rgba(255, 255, 255, 1) 64%
      );
    }
  }
}
