div.wort_filters_wrapper.picked_place {
  // grid-template-rows: auto auto 5%;
}
.subpage_hero_wrapper.picked_place_cnt.fixed-height.fixed-height {
  @include media-max($lg) {

    grid-template-columns: 1fr;
  }
  @include media-max($md){
    height: auto;
  }
}
.picked_place {
  .contact {   
    width: 100%;
    border-bottom: 1px solid $border;
    font-size: 18px;
    @include media-max($lg) {
      margin-top: 20px;
      margin-left: 15px;
    }
    @include media-max($md) {
      margin-top: rem(20px);
      margin-left: 0;
    }
    div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 20px;
      @include media-max($lg) {
        margin-bottom: rem(15px);
      }
    }
    h3 {
      font-size: 17px;
      margin-bottom: 0;
    }
    p {
      font-size: 15px;
    }
  }
  .wort_filters.wort_options {
    padding-top: 20px;
    @include media-max($lg) {
      padding-top: 20px;
    }

    img {
      margin-left: 0;
    }
    label {
      display: flex;
      align-items: center;
    }
  }
  .mini_map {
    border: none;
    width: 100%;
  }
}
.subpage_hero_wrapper.picked_place_cnt {
  position: relative;
  @include media-max($md) {
    grid-template-columns: 1fr;
    max-height: none;
    height: auto;
  }
  .wort_filters_wrapper.picked_place {
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-min($md) {
      left: 65% !important;
      width: 45%;
      padding: 20px;
    }
    @include media-min($lg) {
      left: 80% !important;
      width: 20%;
    }
    @include media-max($md) {
      position: static;
      display: block;
      background: #fff;
      padding: 20px 10px;
      .wort_options {
        position: static;
        #filters_scroll {
          padding-left: 0 !important;
        }
      }
    }
    .wort_filters {
      overflow: visible;
      background-color: unset;
      height: auto;
      min-height: auto;
      max-height: unset;
      width: 100%;
      #filters_scroll {
        overflow: visible;
      }
    }
    .scroll_down_subpage {
      margin-top: auto;
    }
  }
}
