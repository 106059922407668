footer {
  a:hover,
  :focus {
    color: $orange !important;
  }
  a:hover i {
    color: $orange;
  }
}

.nav_link {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  @include media-max($lg) {
    font-size: 16px;
  }
  @include media-max($md) {
    font-size: 14px;
    font-weight: 700;
  }
  &:hover,
  &:active {
    color: unset;
  }
}
.uk-sticky-fixed {
  width: 100% !important;

  background: $green;
}
.uk-accordion-content {
  flex-direction: column;
  li {
    font-size: rem(16px);
  }
}
.uk-accordion-title {
  color: #fff;
}
.uk-accordion-title::before {
  display: none;
}
.uk-icon {
  // transition: .3s all ease-in-out;
}

.uk-subnav-pill {
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.uk-subnav-pill > li > a {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 0;
    height: 5px;
    background-color: $green;
    transition: 0.3s all ease-in-out;
  }
  &:hover {
    &::after {
      width: 100%;
    }
  }
}
.uk-subnav-pill > .uk-active > a {
  background-color: transparent;
  color: $orange;
}
.uk-subnav-pill a {
  &:hover {
    color: $orange;
  }
}
.uk-modal {
  background: #2c3e50d5;
}
.uk-modal-dialog {
  border-radius: 25px;
  // max-height: 100%;

  li {
    display: flex;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    color: $text;
  }
  h3 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
  }
}
.uk-modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom: 0;
  position: relative;
  span {
    opacity: 0.5;
    font-size: 15px;
    font-weight: 500;
    color: $gray_txt;
  }
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url("../assets/icons/separator_mobile.png");
    background-size: contain;
  }
}
.slider_inner_wrapper_all_events {
  .slider_content {
    @include media-max($sm) {
      min-width: auto !important;
    }
  }
}
.mapTooltip {
  &:hover {
    color: #fff;
  }
}
.switcher_tabs {
  min-height: 30px;
}
.uk-slidenav-next,
.uk-slidenav-previous {
  svg {
    polyline {
      stroke: #000 !important;
    }
  }
}
.uk-dropdown-nav {
  li {
    a {
      color: #000;
      font-size: 15px;
      overflow-wrap: break-word;
      word-break: break-word;
      white-space: break-spaces;
      max-width: 100%;
      line-height: 15px;
    }
  }
  li.uk-active a {
    color: $orange;
  }
  li:not(:last-of-type) {
    border-bottom: 1px solid $orange;
    padding-bottom: 8px;
    margin-bottom: 10px;
  }
}
.uk-dropdown.uk-open {
  padding: 15px;
  @include media-max($sm) {
    padding: 20px 15px !important;
  }
}
a:focus {
  color: $orange !important;
}
