.hero_section {
    position: relative;
    z-index: 0;
    color: #fff;

    
    padding-top:rem(100px);
    padding-bottom:rem(100px);
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .pro_search {
        > span {
            display: block;
            margin-bottom: rem(28px);
            font-size: 34px;
            font-weight: 600;
            @include media-max($sm) {
                font-size: 30px;
                margin-bottom: 15px;
            }
        }
    }
    
    @include media-max(965px) {
        .pro_search {
            margin-bottom: 80px;
         
        }
    }
   
    .hero_slideshow {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #090909;
        z-index: -1;
        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: #090909;
            opacity: 0.6;
        }
        > .uk-slideshow-items {
            height: 100%;
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }

    @media (max-width: 960px) and (orientation: landscape) {
        .pro_search > span {
            font-size: 24px;
            margin-top:15px;
            margin-bottom: 10px;
        }
        .pro_search__form {
            min-height: 55px;
        }
        .slider_1 {
            bottom: 3px;
        }
        .slider_1 ul li .slider_content {
            max-height: 80px;
        }        
        .slider_title {
            font-size: 15px;
        }
    }

}
