.subpage_hero {
    padding-top: rem(250px);
    @include media-max($md) {
        padding-top: rem(120px);
 
    }
    .submenu_wrapper {
        li {
            display: flex;
        }
        a {
            font-size: 13px;
            color: #B3BDC7;
        }
        ul {
            display: flex;
            flex-wrap: wrap;

        }
    }
    .hero_container {
        display: flex;
        gap: 100px;
        @include media-max($lg) {
            flex-direction: column;
            gap: 40px;
        }
        .event_photo {
            flex: 1;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-bottom-right-radius: 25px;
            }
        }
        .event_description {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            line-height: 28px;
            color: $text;
            label {
                font-size: 24px;
                font-weight: 500;
                @include media-max($md) {
                    font-size: 20px;
                } 
            }
            h1 {
                font-size: 62px;
                font-weight: 500;  
                @include media-max($md) {
                    font-size: 34px;
                }
            }
            h2 {
                font-size: 62px;
                font-weight: 500;
                @include media-max($md) {
                    font-size: 34px;

                }          
                
            }
            h3 {
                font-size: 16px;
            }
            .info {
                display: flex;
                gap: rem(150px);
                @include media-max($md){
                    gap:rem(40px)
                }
                p:first-child {
                    margin-bottom: 10px;
                }
            }
            hr {
                margin-top: 20px;
            }
            
        }
    }


    

}
.subpage_hero.news_hero {
    .event_description {
        justify-content: flex-start;
    }
}
.picked_place_bg {
    height: 100%;
    min-height: calc(100vh - 160px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
   
    @include media-max($md) {
        background-position: bottom;
    }
    
}
#event_box_grid {
    flex-wrap: wrap;
    margin-top: rem(20px);
    margin-bottom: rem(40px);
    margin-right: 0;
    column-gap: 10px;
    row-gap: 30px;
    li {
        flex-basis: 24%;
        @include media-max($xl) {
            flex-basis: 31%;

        }
        @include media-max($md) {
            flex-basis: 45%;
        }
        @include media-max($sm) {
            flex-basis: 100%;
        }
    }
    @include media-max($xl) {
        column-gap: 20px;
    }
    .slider_content {
        max-width: 320px;
        @include media-max($md) {
            max-width: 100%;
        }
    }
}