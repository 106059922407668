.wrapper {
  max-width: 100%;
  padding-left: rem(240px);
  padding-right: rem(240px);
  @include media-max($lg) {
    padding-left: rem(90px);
    padding-right: rem(90px);
  }
  @include media-max($md) {
    padding-left: rem(15px);
    padding-right: rem(15px);
  }
}
.wrapper_2 {
  max-width: 100%;
  padding-left: 0;
  padding-right: rem(240px);
  @include media-max($lg) {
    padding-left: rem(90px);
    padding-right: rem(90px);
  }
  @include media-max($md) {
    padding-left: rem(15px);
    padding-right: rem(15px);
  }
}
.wrapper-form {
  max-width: 100%;
  padding-left: rem(340px);
  padding-right: rem(340px);
  @include media-max($lg) {
    padding-left: rem(90px);
    padding-right: rem(90px);
  }
  @include media-max($md) {
    padding-left: rem(15px);
    padding-right: rem(15px);
  }
}



.stop_scroll {
  overflow: hidden;
  height: 100%;
  body {
    position: relative;
  }
}
.clicked {
  background: $green;
  color: #fff;
}
.modal_toggle {
  left: 0 !important;
  @include media-min($md) {
    left: 85% !important;
  }
}
.flip_arrow {
  transform: rotate(180deg);
}
.opened_flex {
  display: flex !important;
}
.auto_height {
  max-height: max-content !important;
  overflow: visible;
}

.dashed_separator {
  border-top: none;
  position: relative;
  margin: rem(35px 0);
  width: 100%;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: -240px;
    width: calc(100% + 245px * 2);
    height: 2px;
    background: url("../assets/icons/separator.png");
  }
}
.heading_1,
.heading_2 {
  font-size: 30px;
  color: $text;
  font-weight: 600;
  @include media-max($md) {
    font-size: 26px;
  }
}
.heading_1 {
  color: $green;
}
h2.heading_1 {
  font-size: rem(25px);
}
.active_page {
  text-decoration: underline;
  color: $text !important;
}
.padding_top_large {
  padding-top: rem(250px);
  @include media-max($md) {
    padding-top: rem(70px);
  }
}
.adjust_height {
  height: 100vh;
  @include media-max($md) {
    height: calc(100vh - 30px);
  }
}
.nav_link svg {
  transition: 0.2s all ease-in-out;
}
.shadow-bottom {
  -webkit-box-shadow: 0px 12px 12px -10px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0px 12px 12px -10px rgba(0, 0, 0, 0.28);
  box-shadow: 0px 12px 12px -10px rgba(0, 0, 0, 0.28);
}
.shadow-left {
  -webkit-box-shadow: -17px 0px 24px -19px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: -17px 0px 24px -19px rgba(0, 0, 0, 0.28);
  box-shadow: -17px 0px 24px -19px rgba(0, 0, 0, 0.28);
}
.shadow-full {
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.23);
}
.scrollbar-custom {
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background-color: $gray-col-t;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $gray-col;
  }
}
.plus-symbol {
 margin-left: rem(50px);
}
.hidden-content {
  visibility: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}
.link_hl {
  font-size: 18px;
  color: $orange !important;
}
.page-intro {
  position: absolute;

  min-height: auto;
  width: 100%;
  top: 100px;
  z-index: 3;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid $border;
  padding: 15px 2.7rem;
  @include media-max($lg) {
    padding: 15px 1rem;
  }
  h1,h2 {
    font-size: 20px;
    margin-bottom: 0;
  }
}
.text-block {
  margin: 1.5em 0;
}