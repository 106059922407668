// Style alerts for our CMS
// Bootstrap's styles by default
.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  position: fixed;
  top: 5rem;
  right: 1rem;
  // z-index: 50000;
  z-index: 0;
  &-success {
    background-color: #dff0d8;
    border-color: #d0e9c6;
    color: #3c763d;
  }
  &-info {
    background-color: #d9edf7;
    border-color: #bcdff1;
    color: #31708f;
  }
  &-warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #8a6d3b;
  }
  &-danger {
    background-color: #f2dede;
    border-color: #ebcccc;
    color: #a94442;
  }

  button {
    float: right;
    border-radius: 1000px;
    border: 1px solid #000;
    margin-left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 2rem;
    display: grid;
    line-height: 1.6rem;
    justify-content: center;
    justify-items: center;
    padding: 0;
    align-content: center;
  }

}
