.pro_search {
    @include media-max($md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100%;
    }  
    &__form {
        background:rgba(255, 255, 255, 0.041);
        backdrop-filter: blur(4px);
        min-width: 780px;
        min-height: 65px;
        border-radius: 35px;
        display: flex;
        border: 1px solid #fff;   
        @include media-max($md) {
            min-width: 100%;
        }  
    }
    &__input,
    &__icon {
        outline: none;
        border: none;
        background-color: transparent;
    }
    &__input {      
        width: 100%;       
        padding-left: rem(80px);
        color: #fff ;
        font-size: 18px;    
        @include media-max($md) {
            padding-left: 45px;
        }
        @include media-max($sm) {
            padding-left: 20px;
        }
    }
    &__icon {        
        min-height: 100%;
        padding-right: 45px;
        @include media-max($sm) {
            padding-right: 20px;
        }
    }
}