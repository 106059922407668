.map_small_subpage {
    display: flex;
    gap: rem(40px);
    @include media-max($md) {
        flex-direction: column;
    }

    .small_map {
        min-height: 420px;
        flex: 3;
        border-radius: 25px;
        background:gray;
        @include media-max($md) {
            flex: 1;
        }
    }
    .boxes {
        flex: 1;
    }
}