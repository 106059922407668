.editable {
  line-height: 28px;
  color: $text;
  hr {
    margin-top: 20px;
  }
  img {
    width: 100%;
    max-height: 50vh;
    object-fit: cover;
    margin-bottom: rem(50px);
  }
  .author {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .text-block {
    max-width: 80%;
    margin: auto;
    @include media-max($sm) {
      max-width: 100%;
    }
    p {
      margin-bottom: rem(50px);
    }
  }
}
