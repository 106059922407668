.header_subpage {
  z-index: 5;
  .header__wrapper {
    padding: 0;
    top: 40px;
    left: 60px;

    .nav_wrapper {
      background: #fff;
    }

    .header__navigation {
      padding: 0 2.7rem;
      @include media-max($lg) {
        padding: 0 1rem;
      }
      @include media-max($md) {
        display: grid;
        grid-template-rows: 1fr;
      }

      > ul {
        color: $text;
        @include media-max($lg) {
          padding: 0 20px;
        }

        @include media-max($md) {
          width: 100%;
          padding: 0;
        }
        .nav__item {
          margin-right: rem(80px);
          @include media-max($lg) {
            margin-right: rem(30px);
          }
          .inner_cnt {
            @include media-max($md) {
              padding: 8px 10px;
            }
            .nav_link {
              @include media-max($sm) {
                font-size: 1rem;
              }
            }
          }
        }
        .nav__item:last-of-type {
          margin-right: 0;
        }
      }
      > div {
        padding: 15px 0;
        position: relative;
        @include media-max($md) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 0;
        }
        @include media-max($sm) {
          padding: 15px 0;
        }
        i {
          @include media-min($md) {
            // display: none;
          }
        }
      }
      .logo_cnt {
        // padding-left: 2.75rem;

        @include media-max($md) {
          padding: 0;
          img {
            max-width: 235px;
          }
        }
        &:after {
          display: none;
        }
      }
    }

    .submenu_wrapper {
      height: 70px;
      display: grid;
      grid-template-columns: 75px 1fr;
      align-items: center;
      position: relative;
      @include media-max($md) {
        min-height: rem(50px);
      }
      a {
        font-size: 13px;
        color: #b3bdc7;
        justify-self: center;
        &:hover {
          color: $green;
        }
      }
      a.active_page {
        text-decoration: underline;
        color: $text;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        // height: 100%;
        i {
          vertical-align: baseline;
        }
      }

      .right_side {
        height: 100%;
        display: grid;
        align-items: center;
        position: relative;
        z-index: 1;
        grid-template-columns: 1fr 55px;
        @include media-min($md) {
          grid-template-columns: 1fr;
        }
        @include media-max($md) {
          padding-right: 36px;
        }
        @include media-max($sm) {
          padding-right: 22px;
        }
        > div {
          display: flex;
          height: 100%;
          color: #000;
          align-items: center;
          padding-left: 20px;

          @include media-max($md) {
            font-size: 18px;
            border-bottom-right-radius: 25px;
          }
          @include media-max($sm) {
            font-size: 16px;
          }
          p {
            margin: auto 0;
            padding-left: 20px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
          h1 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0;
            @include media-max($md) {
              font-size: 18px;
            }
            @include media-max($sm) {
              font-size: 16px;
            }
          }
        }
        ul {
          padding-left: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          background: #fff;
          border-bottom-right-radius: 25px;
          @include media-max($md) {
            display: none;
          }
        }
      }
    }
  }
  // -----------
  .menu__toggle {
    position: static;
    width: auto;
    cursor: pointer;
    svg {
      line {
        stroke: #000;
      }
    }
  }
  .menu__close {
    cursor: pointer;
    width: max-content;
    align-self: end;
    padding-right: 10px;
    margin-left: auto;

    position: absolute;
    top: 25px;
    right: 10px;

    @include media-min($md) {
      display: none;
    }
    .uk-icon {
      svg {
        path {
          fill: $green;
          stroke: $green;
        }
      }
    }
  }
  .nav_second {
    &_inner {
      display: flex;
      // flex-direction: column;
    }
  }
  .nav_cnt {
    @include media-max($md) {
      display: none;
    }
  }
  .inner_cnt {
    position: relative;
    text-align: center;
    @include media-max($lg) {
      padding: rem(10px 10px);
    }

    @include media-max($md) {
      text-align: left;
      padding: 0;
      max-width: 100%;
    }
    .dropdown {
      flex-direction: column;
      background: $green;
      // position: static;
      display: none;
      z-index: 10;
      @include media-min($md) {
        overflow: auto;
      }
      @include media-max($md) {
        display: flex;
        max-height: 0;
        overflow: hidden;
        -webkit-transition: max-height 1s;
        -moz-transition: max-height 1s;
        -ms-transition: max-height 1s;
        -o-transition: max-height 1s;
        transition: max-height 1s;
        transition: 0.1s all;
      }
      li {
        margin: rem(0 5px);
        text-align: left;
        font-size: rem(18px);
        position: relative;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.527);
        @include media-max($lg) {
          // margin: rem(10px 20px);
        }

        @include media-max($md) {
          // margin: rem(10px 0);
          padding-left: 10px;
        }

        a {
          position: relative;
          z-index: 1;
          margin-left: -20px;
          background: $green;
        }
      }
      li:first-child {
        border-top: 1px solid rgba(255, 255, 255, 0.527);
      }
    }
  }
  .nav_cnt_two {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    color: $text;
    z-index: 10;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-direction: column;
    z-index: 1000;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 15px 20px;
    .logo_cnt {
      padding-right: 0;
      margin: 20px auto;
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
      overflow: auto;
      .nav__item {
        text-align: center;
      }
    }

    .nav_link {
      // color: $text;
      font-size: 18px;
      margin-bottom: 25px;
    }
  }

  .nav_cnt_two {
    @include media-max($md) {
      .logo_cnt {
        max-width: 60%;
      }
    }
  }
}
.filter_btn {
  text-align: center;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  right: 10px;
  top: 50%;
  background-color: $orange;
  border: 1px solid $orange;
  @include media-max($md) {
    width: 40px;
    height: 40px;
  }
  // border-radius: 50%;
}
header {
  position: relative;
}
