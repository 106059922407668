.header_third {
  .menu__toggle {
    gap: 0;
    .burger_menu {
      margin-left: 10px;
    }
  }
  .nav_cnt {
    color: $text;
    padding: 0 1rem;
  }
  .header_third_mobile {
    color: $text;
  }
  .pro_btn_dark {
    &:hover {
      color: $green;
    }
  }
  .uk-sticky.uk-active {
    .nav_cnt {
      color: #fff;
    }
    .pro_btn_dark {
      color: #fff;
      border-color: #fff;

      svg {
        path {
          stroke: #fff;
        }
      }
    }
    .burger_menu {
      margin-left: 10px;
      line {
        stroke: #fff;
      }
    }
  }
  .pro_btn_dark {
    padding: 1rem 2.125rem;
    @include media-max($md) {
      padding: rem(10px) rem(20px);
    }
  }
}
.header_third.mobile_open {
  .nav_cnt {
    color: #fff;
  }
  .pro_btn_dark {
    border-color: #fff;
    color: #fff;
    margin-top: auto;
  }
}
.header_third {
  .map_btn {
    img {
      @include media-max($sm) {
        width: 20px;
      }
    }
  }
}
