.img_txt_container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: rem(100px);
    @include media-max($md) {
        grid-template-columns: 1fr;
        gap: rem(40px);
    }
 
    .column:nth-child(2) {
     .inner_column {
         height: 100%;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
     }
    }
    img {
        height: 100%;
        object-fit: contain;
    }
}
.green_dot {
  
    align-items: center;
    justify-content: center;
    gap: 10px;
    .uk-icon {              
        justify-self: center;
        margin-right: 5px;
              
    }
        
    p {
            font-size: 16px;
        font-weight: 500;
    }
         
    > .uk-icon {
         border-radius: 50%;
         background:$green;
        color: #fff;
                
    }
}