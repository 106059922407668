.slider_section {
  margin: rem(85px 0);
  @include media-max($lg) {
    margin: rem(45px 0);
  }
}

// .slider_1,
// .slider_2 {
.slider_1 {
  position: absolute;
  bottom: 75px;
  left: 0;
  width: 100%;
  @include media-max($sm) {
    bottom: 60px;
  }

  .slider_inner_wrapper {
    padding-left: rem(240px);
    @include media-max($lg) {
      padding-left: rem(90px);
    }
    @include media-max($md) {
      padding-left: rem(15px);
    }
  }
}

.slider_subtitle {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  pointer-events: none;
}
.slider_title {
  font-size: 26px;
  font-weight: 600;
  pointer-events: none;
  @include media-max($sm) {
    font-size: 16px;
  }
}

.slider_1 {
  .slider_nav {
    margin-bottom: 15px;
    @include media-max($sm) {
      display: none;
    }
  }
  ul {
    gap: 30px;
    margin-right: 240px !important;

    li {
      position: relative;
      border-radius: 30px;
      border: 1px solid #fff;
      background: inherit;
      &::after {
        transition: 0.2s all ease-in-out;
        border-radius: 40px;
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        z-index: -1;
        background-color: rgba(255, 255, 255, 0.137);
        -webkit-filter: blur(10px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
        filter: blur(10px);
      }
      &:hover:after {
        background-color: rgba(255, 255, 255, 0.233);
      }
      a:hover {
        color: #fff;
      }

      .slider_content {
        min-width: 330px;
        height: 130px;
        padding: rem(15px 20px);
        display: flex;
        gap: rem(25px);
        .slider_text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          h3 {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
          }
          h2 {
            font-size: 26px;
            text-transform: lowercase;
            font-weight: 600;
          }
        }
        @include media-max($sm) {
          min-width: max-content;
          height: 85px;
        }
        img {
          border-radius: 20px;
        }
      }
    }
  }
}
.slider_2,
.slider_3 {
  .slider_inner_wrapper {
    margin-top: rem(25px);
    padding-left: rem(240px);
    @include media-max($lg) {
      padding-left: rem(90px);
      bottom: 75px;
    }
    @include media-max($md) {
      padding-left: rem(15px);
    }
  }
  .slider_nav {
    color: $green;
    @include media-max($sm) {
      display: none;
    }
  }
  ul.uk-slider-items {
    gap: rem(20px);
    margin-right: rem(240px);

    img {
      object-fit: cover;
    }
    li {
      position: relative;
      .img_desc,
      .event_box_desc {
        position: absolute;
        bottom: 5px;
        left: 10px;
        color: #fff;
        font-size: 14px;
        max-width: 100%;
        text-shadow: 0px 0px px #000000;
      }
    }

    .slider_content,
    .event_box_grid {
      padding: rem(10px 10px 30px);
      border: 1px solid $border;
      // min-width: 360px;
      max-width: 360px;

      min-height: 510px;
      max-height: 510px;

      display: flex;
      flex-direction: column;
      height: 100%;

      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      user-select: none;

      @include media-max($sm) {
        max-width: 300px;
      }
      > img {
        border-radius: 20px;
        object-fit: cover;
        width: 100%;
      }
      a > img:first-of-type {
        width: 100%;
      }
      .slider_text {
        padding: 0 15px;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 15px;
        span,
        a {
          display: flex;
          justify-content: flex-start;
          gap: 15px;
          align-items: center;
          .uk-icon {
            justify-self: center;
          }
        }
        span {
          font-size: 13px;
        }
        img {
          max-height: 15px;
        }
        p,
        h3 {
          font-size: 16px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        h3 {
          margin-bottom: 5px;
          -webkit-line-clamp: 2;
        }
        a:last-child {
          gap: 10px;
          margin-top: auto;
        }
        a > .uk-icon {
          border-radius: 50%;
          background: $green;
          color: #fff;
          max-height: 25px;
          max-width: 25px;
        }
        a > p {
          font-weight: normal;
        }
        .link_desc {
          margin-top: 0;
        }
      }
      .date.slogan,
      .slogan {
        font-size: 15px;
        color: gray;
        pointer-events: none;
      }
    }
  }
}
.slider_2 {
  width: 100%;

  img {
    max-width: 330px;
    width: 100%;
    height: 100%;
  }
}
.slogan {
  font-size: 15px;
  color: gray;
  pointer-events: none;
}

.slider_3 {
  .picture_badge {
    position: relative;
    .badge {
      text-transform: uppercase;
      position: absolute;
      top: 15px;
      left: 18px;
      background: $green;
      color: #fff;
      padding: rem(5px 15px);
    }
  }
  ul.uk-slider-items {
    .slider_content {
      .slider_text {
        line-height: 26px;
        h3,
        h4 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
        }
        > div {
          margin-bottom: rem(30px);
          p {
            span {
              font-size: 14px;
              display: block;
              pointer-events: none;
            }
          }
          p:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
          }
        }
        // a {
        //   // margin-top: rem(30px)

        // }
      }
    }
  }
}
