footer {
  .footer_sponsors {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: white;
    width: 100%;
    margin: 1.5em 0;
    @include media-max($sm){
      justify-content: center;
    }

    &_col {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: calc(25% - 35px);
      &:not(:last-of-type){
        margin-right: 35px;
      }

      span {
        font-size: 16px;
        font-weight: 500;
      }

      & img {
        width: auto;
        min-height: 150px;
        max-height: 150px;
      }
    }
  }

  .footer_wrapper {
    background: $black_bg;
    color: $white;
    padding: 1.8rem 2.7rem 0 2.7rem; 
    @include media-max($sm) {
      padding: 1.8rem 1rem 0 1rem; 
  
    }
  }

  .footer_bottom {
    background: $black_bg;
    color: $white;
    padding: 1.8rem 2.7rem; 
    @include media-max($sm) {
      padding: 1.8rem 1rem; 
  
    }

    display: flex;
    justify-content: space-between;   
    font-size: rem(15px);
    color: $gray_txt;
    @include media-max($sm) {
     flex-direction: column;  
    }
    p:nth-child(2) {
      text-align: right;
      @include media-max($sm) {
        text-align: left;
       }
    }

    & > .footer_menu > a:not(:last-of-type) {
      margin-right: 1.5em;
    }

    .copy {
      margin-right: rem(50px);
    }
    .about {
      color: #fff;
    }
  }
}
