.subpage_hero_wrapper {
  height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: 1fr;

  @include media-max($md) {
    display: block;
  }
  .wort_map {
    width: 100%;
    height: 100%;
    background: #b3b3b3;
    border-bottom-right-radius: 35px;
  }
  .wort_filters_wrapper {
    position: relative;
    padding: 50px 0 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 100%;
    bottom: 0;
    width: 100%;
    background: #f5f5f5f5;
    z-index: 6;
    padding: 50px 10px;
    transition: 0.2s all ease-in-out;
    @include media-min($md) {
      width: 15%;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    
    ::-webkit-scrollbar {
      width: 6px;
    }
    ::-webkit-scrollbar-track {
      background-color: $gray-col-t;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $gray-col;
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
  }
  .wort_filters {
    padding-top: 10px;
    padding-right: 15px;
    overflow: hidden;
    position: relative;

    padding: rem(10px 0);
    position: relative;
    overflow-y: auto;
    width: 85%;
    min-height: 90%;
    max-height: 90%;
    z-index: 5;
    border-radius: 5px;
    background: #fff;
    z-index: 2;
    @include media-min($md) {
      width: 95%;
    }
    // }
    .close {
      display: none;
      @include media-max($md) {
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        text-align: right;
        margin-bottom: 10px;
        svg {
          path {
            // stroke: #000 !important;
          }
        }
      }
    }
    ul {
      overflow: auto;
      height: 100%;
      padding-left: rem(40px);
      padding-right: rem(40px);
      @include media-min($md) {
        padding-left: rem(5px);
        padding-right: rem(5px);
      }
      @include media-max($lg) {
        padding-left: rem(15px);
      }
      @include media-max($md) {
        height: 90%;
      }
      li:first-child {
        margin-top: 20px;
      }
      li {
        margin-bottom: 20px;
        font-size: 16px;
        @include media-max($xl) {
          font-size: 15px;
        }
        @include media-max($md) {
          font-size: 14px;
        }

        input,
        img {
          margin: 0 10px;
        }
        img {
          max-width: 35px;
        }
      }
      ::-webkit-scrollbar {
        width: 5px;
      }
    }
  }
  .scroll_to_bottom {
    position: relative;
    z-index: 2;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    margin: auto;

    @include media-max($md) {
      display: none;
    }
  }
}
.subpage_hero_wrapper.fixed-height {
  height: calc(100vh - 160px);
}

.route_map,
.route_video {
  iframe {
    width: 100%;
    height: 60vh;
  }
}

.charts {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      max-height: 500px;
    }
    img {
      height: 100%;
      object-fit: contain;
    }
  }
}
.routes_img_chart1 {
}
