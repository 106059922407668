.m-form {
  margin-top: rem(50px);
  margin-bottom: rem(50px);
  width: 100%;
  &__item {
    margin-bottom: rem(50px);
  }
  .uk-input:focus, .uk-select:focus, .uk-textarea:focus {
    border-color: $orange;
  }
  .uk-input, .uk-select, .uk-textarea {
    border:1px solid #717171;
    min-height: rem(60px);
  }
  .uk-form-stacked .uk-form-label {
    font-weight: 500;
    color: $text;
  }
  .file-select {
    position: relative;
    border:1px solid #717171;
    width: 100%;
    background-color: #f3f3f3;
    .add-photo {
      background-color: #f3f3f3;
    }
    .orange-btn {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      border: none;
      font-size: 14px;
      margin-left: auto;
      max-height: 95%;
      padding: 10px 45px;
      @include media-max($md) {
        font-size: 12px;
      }
      @include media-max($sm) {
        font-size: 10px;
        right: 10px;
        padding: 8px 12px;
      }
    }
    &-input {
      border: none; 
      background-color: #f3f3f3;  
      text-overflow: ellipsis;
    }
  }
}
.uk-form-stacked .uk-form-label {
  font-weight: 500;
  color: $text;
  margin-bottom: 8px;
  font-size: 12px;
}
.m-form-btn {
  border: none ;
  margin-left: auto;
  min-width: 220px;
  text-align: center;
  font-size: 17px;
}
