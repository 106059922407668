.header__wrapper {
  top: 0;
  z-index: 1;
  padding-top: rem(60px);
  padding-bottom: rem(60px);
  width: 100%;
  align-items: center;

  @include media-max($md) {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
  }
}
.header__navigation {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: rem(15px 0);
  > ul {
    display: flex;
    margin-left: auto;
    font-size: rem(20px);
    font-weight: 500;
    color: $white;
    align-items: center;
    @include media-max($md) {
      padding: 0;
    }
  }
}
.pro_btn {
  display: flex;

  align-items: center;
  justify-content: center;
  margin-left: auto;
  @include media-max($lg) {
    padding: rem(10px 20px);
    margin-left: 0;
  }
  @include media-max($md) {
    padding: rem(5px 20px);
    height: max-content;
  }
}
.inner_cnt {
  position: relative;
  text-align: center;
  // transition: .1s all;
  @include media-max($lg) {
    padding: rem(10px 20px);
  }

  @include media-max($md) {
    text-align: left;
    padding: 0;
    max-width: 100%;
  }
  .dropdown {
    position: absolute;
    max-height: rem(500px);
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    background: $green;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    display: none;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $text;
    }
    @include media-min($md) {
      overflow: auto;
    }
    @include media-max($md) {
      position: static;
      display: flex;
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height 1s;
      -moz-transition: max-height 1s;
      -ms-transition: max-height 1s;
      -o-transition: max-height 1s;
      transition: max-height 1s;
      transition: 0.1s all;
    }
    li {
      margin: rem(0 5px);
      padding: rem(10px 0);
      text-align: left;
      font-size: rem(18px);
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 10px;

      @include media-max($md) {
        margin: rem(10px 0);
      }

      a {
        position: relative;
        z-index: 1;
        margin-left: -20px;
        background: $green;
      }
      &:hover a {
        transform: translateX(16px);
      }
    }
    li:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.527);
    }
    li:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.527);
    }
  }
}

.logo_cnt {
  position: relative;
  min-width: rem(300px);
  max-width: rem(350px);
  padding-right: rem(60px);
  &::after {
    display: block;
    content: "";
    position: absolute;
    top: -7.5px;
    left: 100%;
    height: calc(100% + 15px);
    width: 1px;
    background: $white;
    opacity: 0.5;

    @include media-max($md) {
      display: none;
    }
  }
}

.menu__toggle {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  padding-top: 15px;
  padding-bottom: 15px;
  span {
    transition: 0.1s all ease-in-out;
    align-self: center;
  }
  @include media-max($sm) {
    gap: 20px;
  }
}

.header__wrapper.mobile_open {
  #nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: $green;
    flex-direction: column;
    z-index: 10000;
    align-items: flex-start;
    justify-content: flex-start;
    padding: rem(25px 30px);
    gap: 15px;
    overflow: auto;
    // overflow-y: auto;
    .logo_cnt {
      margin-bottom: rem(25px);
      max-width: 60%;
    }
    ul.nav_cnt {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    .nav__item {
      width: 100%;
    }

    .menu__close {
      position: absolute;
      top: 25px;
      right: 10px;
    }
    .pro_btn {
      margin-top: auto;
    }
  }
}

.hover-2 {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    transition: transform .5s ease;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: $orange;
    transform:  scaleX(0);
  }
  &:hover:before {
    transform:  scaleX(1);
  }
}

