.txt_img_container {
    display: grid;
    margin: rem(45px 0);
    grid-template-columns: repeat(2, 1fr);
    gap: 150px;
    @include media-max($md) {
        grid-template-columns: 1fr;
        row-gap: 50px;
    }
    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .column:last-child{
        position: relative;
    }
    .inner_column {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: rem(40px);
        font-size: 14px;
        @include media-max($md) {
            grid-template-columns: 1fr;
        }
    }
    .pro_green_btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

}