form {
    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        width: 60%;
        label, p {
            margin-bottom: 10px;
        }
        input {
            min-height: 50px;
        }
        @include media-max($md) {
            width: 100%;
        }
    }
}