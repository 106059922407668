.fourfour {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  min-height: 100vh;
  // padding-top: rem(50px);
  height: 100%;
  width: 100%;
  .columns__1_2 {
    @include media-max(960px) {
      grid-template-columns:1fr;
    }
  }

  svg {
    @include media-max(960px) {
      max-width:50%;
      height:auto;
      margin:auto;
      display:block;
    }
  }
  .uk-container {
    max-width: rem(1000px);

    @include media-max(960px) {
      text-align:center;
    }
  }
  &__wrapper {
    font-size: rem(24px);
    > * {
      margin-bottom: rem(30px);
    }
    h2 {
      font-size: rem(70px);
      margin-bottom:0;
      font-weight: 700;
    }
    h3 {
      font-size: rem(32px);
      font-weight: 700;
    }
  }
}

#lights {
  animation: blink-animation 800ms steps(1, start) infinite;
 }
 @keyframes blink-animation {
  0% {
    fill:#EBEBFF;
  }
  50% {
    fill: orange;
  }
  100% {
    fill:#EBEBFF;
  }
}
.fourfour_wrapper {
  position: relative;
  width: 100%;
  height:100vh;
  .fourfour_content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}