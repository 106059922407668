// ------------------------------------------------------------
// @include font-face($name, $path, $weight: null, $style: null, $exts: woff2 woff);
// Use only woff2, woff formats, both are required
// Example: @include font-face('Font Name', $font-dir+'font-file-name', 400);
// @include font-face('Open Sans', $open-sans-dir+'OpenSans-Regular', 400);
// ------------------------------------------------------------
// Default fonts directory
$fonts-directory: '../assets/fonts/';
$font-dir:'font-dir/';

// example font-face
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: url($fonts-directory + 'Poppins-Bold.eot');
    src: url($fonts-directory + 'Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url($fonts-directory + 'Poppins-Bold.woff2') format('woff2'),
        url($fonts-directory + 'Poppins-Bold.woff') format('woff'),
        url($fonts-directory + 'Poppins-Bold.ttf') format('truetype'),
        url($fonts-directory + 'Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: url($fonts-directory + 'Poppins-SemiBold.eot');
    src: url($fonts-directory + 'Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url($fonts-directory + 'Poppins-SemiBold.woff2') format('woff2'),
        url($fonts-directory + 'Poppins-SemiBold.woff') format('woff'),
        url($fonts-directory + 'Poppins-SemiBold.ttf') format('truetype'),
        url($fonts-directory + 'Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: url($fonts-directory + 'Poppins-Regular.eot');
    src: url($fonts-directory + 'Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url($fonts-directory + 'Poppins-Regular.woff2') format('woff2'),
        url($fonts-directory + 'Poppins-Regular.woff') format('woff'),
        url($fonts-directory + 'Poppins-Regular.ttf') format('truetype'),
        url($fonts-directory + 'Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-display: swap;
    font-family: 'Poppins';
    src: url($fonts-directory + 'Poppins-Medium.eot');
    src: url($fonts-directory + 'Poppins-Medium.eot?#iefix') format('embedded-opentype'),
        url($fonts-directory + 'Poppins-Medium.woff2') format('woff2'),
        url($fonts-directory + 'Poppins-Medium.woff') format('woff'),
        url($fonts-directory + 'Poppins-Medium.ttf') format('truetype'),
        url($fonts-directory + 'Poppins-Medium.svg#Poppins-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}
